/* Stile globale generico */
body {
  margin: 0;
  font-family: sans-serif;
}

/* =========== Header =========== */
.app-header {
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.logo img {
  max-height: 40px;
}

.main-nav ul {
  list-style: none;
  display: flex;
  gap: 10px;
}

.main-nav li a {
  text-decoration: none;
  color: #333;
}

.search-bar {
  display: flex;
  gap: 5px;
}

/* =========== Footer =========== */
.app-footer {
  background-color: #e3e3e3;
  padding: 20px;
  text-align: center;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-bottom: 10px;
}

.footer-links li a {
  text-decoration: none;
  color: #333;
}

.company-info p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body */
body {
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f9f9f9;
  line-height: 1.6;
}

/* Links */
a {
  text-decoration: none;
  color: #007bff;
}

a:hover {
  color: #0056b3;
}

/* Buttons */
button {
  border: none;
  outline: none;
  cursor: pointer;
}

button:focus {
  outline: none;
}

button:hover {
  opacity: 0.9;
}

/* Header Specific */
.header {
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;
}

.header .logo-img {
  max-width: 120px;
  height: auto;
}

.header .header-icons {
  display: flex;
  gap: 15px;
}

.header .header-icons .header-icon {
  color: #333;
  font-size: 1.2rem;
}

.header .header-icons .header-icon:hover {
  color: #007bff;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .header .logo-img {
    max-width: 100px;
  }

}
.navbar {
  background-color: #fa7575; /* Definisci la variabile nel root del tuo progetto */
  padding: 5px 20px;
  color: #000;
  font-size: 16px;
}

.navbar .navbar-left {
  font-weight: bold;
}

.navbar .navbar-right a,
.navbar .navbar-right button {
  margin-left: 15px;
  color: #000;
  text-decoration: none;
  font-size: 16px;
}

.navbar .navbar-right button {
  background: none;
  border: none;
  cursor: pointer;
}

.navbar .navbar-right a:hover,
.navbar .navbar-right button:hover {
  text-decoration: underline;
}

.navbar-mobile {
  font-weight: bold;
  font-size: 14px;
}
.searchbar-container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

.searchbar-form {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 50px;
  background-color: #fff;
  padding: 5px 10px;
  transition: box-shadow 0.3s ease-in-out;
}

.searchbar-form:focus-within {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.searchbar-category {
  border: none;
  background: transparent;
  font-size: 14px;
  padding: 0 10px;
  border-right: 1px solid #ddd;
}

.searchbar-input-wrapper {
  flex: 1;
  position: relative;
}

.searchbar-input {
  border: none;
  outline: none;
  font-size: 14px;
  padding: 10px;
  width: 100%;
}

.searchbar-reset {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  font-size: 14px;
  cursor: pointer;
}

.searchbar-submit {
  background: #e83854;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.searchbar-suggestions,
.searchbar-preview {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
  overflow: hidden;
}

.searchbar-suggestions {
  margin-top: 5px;
}

.searchbar-suggestion-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.searchbar-suggestion-item:hover {
  background-color: #f8f9fa;
}

.searchbar-preview ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.searchbar-preview-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.searchbar-preview-item:hover {
  background-color: #f8f9fa;
}

.searchbar-preview-item img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 4px;
}

.searchbar-preview-item h6 {
  margin: 0;
  font-size: 14px;
}

.searchbar-preview-item p {
  margin: 0;
  font-size: 12px;
  color: #6c757d;
}

.product-slider-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
}

.product-card .card {
  border: none;
  transition: transform 0.3s ease;
}

.product-card .card:hover {
  transform: scale(1.05);
}

.product-card .card-img-top {
  border-radius: 8px;
}

.product-card .card-body {
  text-align: center;
  padding: 10px;
}
.category-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border: none;
  border-radius: 12px;
  overflow: hidden;
  background-color: #fff;
}

.category-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.category-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.category-card .card-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
}

.category-card .subcategories ul {
  padding: 0;
  list-style-type: none;
}

.category-card .subcategories ul li {
  display: inline;
  margin-right: 10px;
}

.category-card .subcategories a {
  font-size: 0.9rem;
  text-decoration: none;
}

.category-card .subcategories a:hover {
  text-decoration: underline;
}